<template>
  <div style="margin-top: 20px;">
    <el-button @click="dialogVisible=true" style="float: right">新增</el-button>
    <el-table
        :data="tableData"
        border>
      <el-table-column
          fixed
          prop="id"
          label="ID">
      </el-table-column>
      <el-table-column
          prop="name"
          label="名称">
      </el-table-column>
      <el-table-column
          prop="host"
          label="地址">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="代理">
        <template slot-scope="scope">
          <div>
            <el-tag type="success">
              {{ scope.row.useProxy }}
            </el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="type"
          label="类型">
      </el-table-column>
      <el-table-column
          prop="listDecorator"
          label="剧集分割符">
      </el-table-column>
      <el-table-column
          prop="description"
          label="描述">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="140">
        <template slot-scope="scope">
          <el-row>
            <el-col :span="12">
              <el-button type="info" size="mini" @click="updData(scope.row)">编辑</el-button>
            </el-col>
            <el-col :span="12">
              <el-button type="danger" size="mini" @click="deleteData(scope.row)">删除</el-button>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="90%"
        :before-close="handleClose">
      <el-form :model="fromData" ref="dynamicValidateForm" label-width="120px">
        <el-form-item label="名称" prop="usrName">
          <el-input v-model="fromData.name"></el-input>
        </el-form-item>
        <el-form-item  label="地址">
          <el-input v-model="fromData.host"></el-input>
        </el-form-item>
        <el-form-item  label="类型">
          <el-input v-model="fromData.type"></el-input>
        </el-form-item>
        <el-form-item label="剧集分割符">
          <el-input v-model="fromData.listDecorator"></el-input>
        </el-form-item>
        <el-form-item  label="代理">
          <el-select v-model="fromData.useProxy" placeholder="请选择">
            <el-option
                label="是"
                :value="true">
            </el-option>
            <el-option
                label="否"
                :value="false">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="描述">
          <el-input v-model="fromData.description"></el-input>
        </el-form-item>

      </el-form>

      <span slot="footer" class="dialog-footer">
    <el-button @click="cancel">取 消</el-button>
    <el-button type="primary" @click="submit">{{ operateType === 1 ? '新增' : '修改' }}</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import MacCms from "../../request/macCms";

export default {
  name: "banner",
  data() {
    return {
      sysAsk: new MacCms(),
      tableData: null,
      dialogVisible: false,
      tempImg: '',
      fit: "contain",
      fromData: {
        "name": "",
        "host": "",
        "type": "",
        "listDecorator":"#",
        "description": ""
      },
      page: {
        "pageNum": 1,
        "pageSize": 100,
      },
      operateType: 1, // 1为新增 0为修改
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    updData(data) {
      this.dialogVisible = true
      this.operateType = 0
      this.fromData = JSON.parse(JSON.stringify(data))
    },
    getData() {
      this.sysAsk.getList(this.page).then(res => {
        this.tableData = res.list
      })
    },
    deleteData(data) {
      this.sysAsk.delData(data.id).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getData()
        }
      })
    },
    show(data) {
      this.dialogVisible = true
      this.fromData = data
    },
    cancel() {
      this.fromData = {}
      this.tempImg = ''
      this.dialogVisible = false
    },
    submit() {
      if (this.operateType === 1) {
        this.sysAsk.insData(this.fromData).then(res => {
          if (res.code == 200) {
            this.fromData = {}
            this.tempImg = ''
            this.dialogVisible = false
            this.getData()
          }
        })
      } else {
        this.sysAsk.updateData(this.fromData).then(res => {
          if (res.code == 200) {
            this.fromData = {}
            this.tempImg = ''
            this.dialogVisible = false
            this.getData()
          }
        })
      }
    },
    handleAvatarSuccess(res, file) {
      this.tempImg = URL.createObjectURL(file.raw);
      this.fromData.bearImg = res.msg
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    }
  },
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

.avatar {
  width: 150px;
  height: 150px;
  display: block;
}
</style>